import React from 'react'

const Address = () => (
  <address>
    <div className="address__street">
      <a href="https://www.google.com/maps/place/24+Chicot+Dr,+Maumelle,+AR+72113/@34.858446,-92.415294,17z/data=!3m1!4b1!4m2!3m1!1s0x87d29f73700b5587:0x7f1356aa958eacb1">
        Adage Financial Consultants <br />
        24 Chicot Dr <br />
        Maumelle, AR 72113
      </a>
    </div>
    <div className="address__phone">
      <a href="tel:1-501-803-0271">(501) 803-0271</a>
    </div>
    <div className="address__email">
      <a href="mailto:david@adagefinancial.com">david@adagefinancial.com</a>
    </div>
  </address>
)

export default Address
