import React from 'react'
import Address from '../components/address'
import { Link } from 'gatsby'

import logo from '../images/logo.svg'

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <Link className="footer__logo" to="/">
        <img alt="Adage Financial Consultants logo" src={logo} />
      </Link>
      <div className="footer__content">
        <ul>
          <li>
            <Link to="/financial-planning" activeClassName="sidebar--active">
              Financial Planning
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.401kmaneuver.com/david-templeton-lp"
            >
              401k Maneuver Program
            </a>
          </li>
          <li>
            <Link
              activeClassName="sidebar--active"
              to="/financial-planning/investment-services"
            >
              Investment Services
            </Link>
          </li>
          <li>
            <Link
              activeClassName="sidebar--active"
              to="/financial-planning/retirement-planning"
            >
              Retirement Planning
            </Link>
          </li>
          <li>
            <Link
              activeClassName="sidebar--active"
              to="/financial-planning/risk-protection"
            >
              Risk Protection
            </Link>
          </li>
          <li>
            <Link
              activeClassName="sidebar--active"
              to="/financial-planning/safe-money"
            >
              Safe Money
            </Link>
          </li>
        </ul>
        <ul>
          <h2>About Us</h2>
          <li>
            <Link activeClassName="sidebar--active" to="/about">
              About Adage Financial
            </Link>
          </li>
          <li>
            <Link activeClassName="sidebar--active" to="/about/david-templeton">
              David Templeton, RFC
            </Link>
          </li>
          <li className="footer__legend">
            <Link activeClassName="sidebar--active" to="/videos">
              Videos
            </Link>
          </li>
          <li className="footer__legend">
            <a
              target="_blank"
              href="https://royalfundmanagement.com/wp-content/uploads/2020/05/Form-CRS-4.28.20.pdf"
            >
              Form CRS
            </a>
          </li>
        </ul>
        <Address />
      </div>
    </div>
    <div className="footer__copyright">
      <p>
        © Copyright {new Date().getFullYear()} Adage Financial Consultants, Inc.
        | All Rights Reserved.
      </p>
      <p>
        Investment Adviser Representative of and investment advisory services
        offered through Royal Fund Management, LLC, a SEC registered investment
        advisory firm - <Link to="/disclosure">Full Disclosure</Link>
        {' | '}
        <a
          target="_blank"
          href="https://royalfundmanagement.com/wp-content/uploads/2020/05/Form-CRS-4.28.20.pdf"
        >
          Form CRS
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
