import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const logo = require('../images/logo.svg')

import NavigationIcon from './navigation-icon'

interface INavigationState {
  showMobileNav: boolean
  showDropDown: number
}

export default class Navigation extends React.Component<{}, INavigationState> {
  constructor(props) {
    super(props)

    this.state = {
      showDropDown: null,
      showMobileNav: false,
    }

    this.onMenuClick = this.onMenuClick.bind(this)
  }

  showDropDownFor(navItem) {
    this.setState({
      showDropDown: navItem,
    })
  }

  onMenuClick() {
    this.setState({ showMobileNav: !this.state.showMobileNav })
  }

  hideDropdown() {
    setTimeout(
      () => !this.state.showDropDown && this.showDropDownFor(null),
      500
    )
  }

  render() {
    return (
      <nav className="nav">
        <div className="nav__container">
          <Link to="/">
            <div className="nav__logo">
              <img alt="Adage Financial Consultants logo" src={logo} />
              <h1>Adage Financial Consultants</h1>
            </div>
          </Link>
          <NavigationIcon onNavClick={this.onMenuClick} />
          <ul
            className={classNames('nav__items', {
              'nav__items--open': this.state.showMobileNav,
              'nav__items--mobile': this.state.showMobileNav,
            })}
          >
            <li
              onClick={() =>
                this.state.showDropDown
                  ? this.showDropDownFor(null)
                  : this.showDropDownFor(1)
              }
              onMouseEnter={() => this.showDropDownFor(1)}
              onMouseLeave={() => this.hideDropdown()}
            >
              <Link activeClassName="sidebar--active" to="/financial-planning">
                Financial Planning
              </Link>
              <div
                className={classNames(
                  'nav__dropdown',
                  'nav__dropdown--mobile',
                  {
                    'nav__dropdown--open': this.state.showDropDown === 1,
                  }
                )}
              >
                <a
                  target="_blank"
                  href="https://www.401kmaneuver.com/david-templeton-lp"
                >
                  401k Maneuver Program
                </a>
                <Link
                  activeClassName="sidebar--active"
                  to="/financial-planning/investment-services"
                >
                  Investment Services
                </Link>
                <Link
                  activeClassName="sidebar--active"
                  to="/financial-planning/retirement-planning"
                >
                  Retirement Planning
                </Link>
                <Link
                  activeClassName="sidebar--active"
                  to="/financial-planning/safe-money"
                >
                  Safe Money
                </Link>
                <Link
                  activeClassName="sidebar--active"
                  to="/financial-planning/risk-protection"
                >
                  Risk Protection
                </Link>
              </div>
            </li>
            <li>
              <a
                target="_blank"
                href="https://royalfundmanagement.com/wp-content/uploads/2020/05/Form-CRS-4.28.20.pdf"
              >
                <b>Form CRS</b>
              </a>
            </li>
            <li
              onClick={() =>
                this.state.showDropDown
                  ? this.showDropDownFor(null)
                  : this.showDropDownFor(2)
              }
              onMouseEnter={() => this.showDropDownFor(2)}
              onMouseLeave={() => this.hideDropdown()}
            >
              <Link activeClassName="sidebar--active" to="/about">
                About Us
              </Link>
              <div
                className={classNames(
                  'nav__dropdown',
                  'nav__dropdown--mobile',
                  {
                    'nav__dropdown--open': this.state.showDropDown === 2,
                  }
                )}
              >
                <Link activeClassName="sidebar--active" to="/about">
                  About Adage Financial
                </Link>
                <Link
                  activeClassName="sidebar--active"
                  to="/about/david-templeton"
                >
                  David Templeton, RFC
                </Link>
              </div>
            </li>
            <li>
              <Link activeClassName="sidebar--active" to="/videos">
                Videos
              </Link>
            </li>
            <li>
              <Link activeClassName="sidebar--active" to="/contact">
                Contact Us
              </Link>
            </li>
            <div className="nav__help">
              <p>
                If you have questions about your financial future, we're here to
                help and welcome the opportunity to work with you to craft a
                financial plan that is as unique as you are.
              </p>
              <Link to="/contact">
                <button>Schedule Your Free Consultation</button>
              </Link>
            </div>
          </ul>
        </div>
      </nav>
    )
  }
}
