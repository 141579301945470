import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navigation from './navigation'
import Contact from './contact'
import Footer from './footer'

interface ILayoutProps {
  children: any
  data?: any
}

const Layout = (props: ILayoutProps) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                // tslint:disable-next-line:max-line-length
                'Adage Financial Consultants is a full - service retirement planning and investment advisory firm, driven by one focused goal, to help clients grow',
            },
            {
              name: 'keywords',
              content:
                // tslint:disable-next-line:max-line-length
                'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Navigation />
        {props.children}
        <Contact />
        <Footer />
      </>
    )}
  />
)

export default Layout
