import React from 'react'

import Address from './address'

const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

interface IContactState {
  name: string
  email: string
  telephone: string
  message: string
  submitted: boolean
}

export default class Contact extends React.Component<
  {},
  Partial<IContactState>
> {
  constructor(props) {
    super(props)

    this.state = { name: '', email: '', telephone: '', message: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e: any) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.setState({ submitted: true }))
      .catch(error => alert(error))
  }

  get renderForm() {
    return (
      <form
        name="afc-contact-form"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="afc-contact-form" />
        <div hidden>
          <label>
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="contact__form__input">
          <label>
            Name
            <input type="text" name="name" onChange={this.handleChange} />
          </label>
        </div>
        <div className="contact__form__input">
          <label>
            Email
            <input type="email" name="email" onChange={this.handleChange} />
          </label>
        </div>
        <div className="contact__form__input">
          Telephone (Optional)
          <label>
            <input type="tel" name="telephone" onChange={this.handleChange} />
          </label>
        </div>
        <div className="contact__form__input">
          <label>
            Message (Optional)
            <textarea name="message" onChange={this.handleChange} />
          </label>
        </div>
        <button type="submit">Send</button>
      </form>
    )
  }

  get renderSuccessMessage() {
    return (
      <div className="contact__success">
        <div>
          <h2>Thank You For Contacting us!</h2>
          <p>We will be in touch with you soon.</p>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section className="contact">
        <div className="contact__header">
          <h1>Take Control Of Your Financial Future</h1>
          <p>Contact us and receive a no obligation, free consultation.</p>
        </div>
        <div className="contact__container">
          <div className="contact__form">
            {this.state.submitted ? this.renderSuccessMessage : this.renderForm}
          </div>
          <div className="contact__info">
            <div className="contact__info__address">
              <Address />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
