import React from 'react'
import { BooleanValueNode } from 'graphql'

interface INavigationIconProps {
  onNavClick: () => any
}

interface INavigationIconState {
  navOpen: boolean
}

export default class NavigationIcon extends React.Component<
  INavigationIconProps,
  INavigationIconState
> {
  constructor(props) {
    super(props)

    this.state = {
      navOpen: false,
    }
  }

  onNavClick() {
    this.setState(
      {
        navOpen: !this.state.navOpen,
      },
      () => this.props.onNavClick && this.props.onNavClick()
    )
  }
  render() {
    return (
      <div
        className={`nav-icon ${this.state.navOpen && 'open'}`}
        onClick={() => this.onNavClick()}
      >
        <span />
        <span />
        <span />
      </div>
    )
  }
}
